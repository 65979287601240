<template>
  <div class="login-container"> 
    <img class="login-logo" src="../assets/nrs.svg" />  
    <form @submit.prevent="login"> 
      <div class="input-areas">
        <input id="e_mail_or_user_name" v-model="form.e_mail_or_user_name" type="text" placeholder="E-mail or Username" required />
        <input id="password" v-model="form.password" type="password" placeholder="Password" required />
      </div>
      <div v-if="message" :class="messageClass">{{ message }}</div> 
 
      <button type="submit" class="login-button">LOGIN</button>
    </form>  
  </div>
</template>


<script>
import axios from 'axios'; 

export default { 
  data() {
    return {
      form: {
        e_mail_or_user_name: '', 
        password: '',
      },
      message: '',
      messageClass: 'error',
    };
  },
  methods: { 
    async login() {
      try {
        const response = await axios.post('/auth/login', this.form);  
        localStorage.setItem('token', response.data.token);
        this.message = 'Giriş başarılı. Yönlendiriliyorsunuz.';
        this.messageClass = 'success';
 
        this.$router.push('/nrs');
        
      } catch (error) {
        console.error('Login error:', error.response ? error.response.data : error.message);
        this.message = 'Lütfen giriş bilgilerinizi kontrol edin.';
        this.messageClass = 'error';
      }
    },
  },
};
</script>



<style scoped>
.login-container {
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.login-logo {
  max-width: 150px;
  height: auto;
  margin-bottom: 30px;
} 

.input-areas {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  min-width: 320px;
}

.login-button {
  background-color: #0b3018;
  color: #cecece;
  border: none;
  padding: 5px 30px;
  font-size: 1em;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  border-radius: 5em; 
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #106e35;
}

.error { 
  color: #d00000;
  margin-bottom: 1em;
}

.success { 
  color: #1ed760;
  margin-bottom: 1em;
} 
 
</style>
